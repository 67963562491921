<template>
  <div class="personal-history">
    <mob-header
      :interaction="false"
      status="info"
      :username="true"
    ></mob-header>
    <div class="main">
      <span v-if="dataList.length == 0" class="dataNull">暂无课程</span>
      <div v-else class="item" v-for="(item, i) in dataList" :key="i">
        <div class="time" v-if="item.data.length > 0">
          {{ item.creationTime.split("-").join(".") }}
        </div>
        <div class="wrap">
          <mob-history
            v-for="(listItem, listI) in item.data"
            :key="listI"
            :data="listItem"
          ></mob-history>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobHistory from "../../components/mob-history.vue";
import { historyList, wechatConfig } from "../../api/user";
import wechat from "../../utils/wxShare";
export default {
  components: {
    MobHeader,
    MobHistory,
  },
  metaInfo: {
    title: "浏览历史",
  },
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getHistoryList();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    getHistoryList() {
      historyList().then((res) => {
        if (res.code == 0) {
          this.dataList = res.list;
        } else if (res.code == 60000) {
          this.$router.replace({ path: "/login" });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-history {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0.1rem 0.1rem 0.9rem;
    .dataNull {
      color: #aaa;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .item {
      padding-top: 0.1rem 0.9rem;
      margin-bottom: 0.15rem;
      background: #fff;
      .time {
        height: 0.25rem;
        line-height: 0.25rem;
        padding-left: 0.1rem;
        font-size: 0.12rem;
      }
    }
  }
}
</style>
