<template>
  <div class="mob-video">
    <div class="info" @click="toWatch()">
      <!-- 课程kv -->
      <div class="kv">
        <img :src="data.kvUrl" alt="" />
        <div class="model"></div>
        <img class="play-icon" src="../assets/play.png" alt="" />
      </div>
      <div class="course-info">
        <!-- 课程标题 -->
        <h3 class="course-title hidden2">{{ data.subject }}</h3>
        <!-- 讲师 -->
        <div class="lecturer">
          <p v-for="(item, i) in data.liveLecturerListMap" :key="i">
            <i>
              <img src="../assets/lecturer.png" alt="" />
            </i>
            <span class="lecturer-name">{{ item.lecturerName }}</span>
            <span class="lecturer-organization">{{ item.companyName }}</span>
          </p>
        </div>
        <div class="channel-wrap">
          <div class="channel">
            {{ data.channelName + "频道" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    // userId: Number,
  },
  data() {
    return {
      // userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
      // userId: this.$store.state.userId,
      // collectStatus: this.data.ymnCollect,
      // appointmentStatus: this.data.ymnSubscribe,
    };
  },
  methods: {
    toWatch() {
      this.$router.push({ path: "/watch/" + this.data.id });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  display: block;
}
h3,
p {
  margin: 0;
}
.mob-video {
  width: 100%;
  overflow: hidden;
  background: #fff;
  .info {
    display: flex;
    padding: 0.1rem;
    border-bottom: 1px solid #ececec;
    .kv {
      flex-shrink: 0;
      position: relative;
      margin-right: 0.1rem;
      width: 1.1rem;
      height: 0.6rem;
      border-radius: 0.02rem;
      overflow: hidden;
      .model {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.1);
        // z-index: 1;
      }
      .play-icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 0.18rem;
        height: 0.18rem;
      }
    }
    .course-info {
      flex-grow: 1;
      .course-title {
        font-size: 0.15rem;
        line-height: 0.17rem;
        // text-align: justify;
        // padding: 0.05rem 0;
      }
      .lecturer {
        font-size: 0.11rem;
        i {
          flex-shrink: 0;
          display: inline-block;
          width: 0.09rem;
          height: 0.09rem;
        }
        .lecturer-name {
          flex-shrink: 0;
          padding: 0 0.05rem;
          color: #000;
          -webkit-transform-origin-x: 0; //缩小后文字居左
          -webkit-transform: scale(0.9);
        }
        .lecturer-organization {
          color: #999;
          max-width: 1.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-transform-origin-x: 0; //缩小后文字居左
          -webkit-transform: scale(0.9);
        }
        p {
          display: flex;
          align-items: center;
          margin-top: 0.03rem;
        }
      }
      .channel-wrap {
        text-align: right;
        .channel {
          display: inline-block;
          height: 0.15rem;
          line-height: 0.15rem;
          padding: 0 0.1rem;
          font-size: 0.09rem;
          color: #49b67b;
          border: 1px solid #49b67b;
          // -webkit-transform-origin-x: 0; //缩小后文字居左
          -webkit-transform: scale(0.98);
        }
      }
    }
  }
}
</style>
